[
  {
    "id": "ben-jerry",
    "title": "Kravinky pro<br>Ben & Jerry's",
    "aside": "benandjerrys.svg",
    "examples": [
      {
        "video": "b-and-j-kolbenova.mp4",
        "description": "Překoplá kravička v metru."
      },
      {
        "video": "b-and-j-ben.mp4",
        "description": "(Broukání melodie z Requiem za sen)."
      },
      {
        "video": "b-and-j-requiem.mp4",
        "description": "Kreativita na hraně brand manuálu."
      }
    ]
  },
  {
    "id": "skoda",
    "title": "Škoda auto",
    "aside": "skoda.svg",
    "examples": [
      {
        "video": "skoda-kdo-driv-mrkne.mp4",
        "description": "Maskováníčko."
      },
      {
        "video": "skoda-enyaq-orange.mp4",
        "description": "Kinetická typografie."
      },
      {
        "video": "skoda-enyaq-blue.mp4",
        "description": "Rámeček pro UI IG, safe zóny."
      }
    ]
  },
  {
    "id": "rb",
    "title": "Raiffeisenbank",
    "aside": "raiffesenbank.svg",
    "examples": [
      {
        "video": "rb-domek.mp4",
        "description": "Maskování a skládání."
      },
      {
        "video": "rb-auto.mp4",
        "description": "Liquify na text."
      },
      {
        "video": "rb-kytara.mp4",
        "description": "Wave warp na struny."
      }
    ]
  },
  {
    "id": "tesla",
    "title": "Tesla",
    "aside": "tesla.svg",
    "examples": [
      {
        "video": "tesla-cisticka.mp4",
        "description": "Particles a odmaskování textu."
      },
      {
        "video": "tesla-zapadne.mp4",
        "description": "Vizualizoval jsem slogany."
      },
      {
        "video": "tesla-pitko.mp4",
        "description": "Naplňovalo mě oživování šablony animace."
      }
    ]
  },
  {
    "id": "jobs",
    "title": "Prace.cz<br>+ Jobs.cz",
    "aside": "praceajobs.svg",
    "examples": [
      {
        "video": "prace-cz.mp4",
        "description": "Lov práce je jeden z mála lovů, který toleruju."
      },
      {
        "video": "jobs-cz.mp4",
        "description": "Simulace rozhraní chatu."
      }
    ]
  },
  {
    "id": "clips",
    "title": "Hudební videoklipy",
    "examples": [
      {
        "video": "clip-pain.mp4",
        "description": "Edit videa pro Paina včetně rozhejbanejch tagů a plynulých střihů."
      },
      {
        "video": "clip-niki.mp4",
        "description": "Jeden ze dvou klipů pro Dvojlitrboyzz."
      },
      {
        "video": "clip-young-havel.mp4",
        "description": "Komplet klip včetně kostýmu."
      }
    ]
  }
]
